/*
// ----------------------------------------------
// Usage example:
// For IE set $mq-support to false.
// Set the fixed value.
// Then use mixins to test whether styles should be applied.
// ----------------------------------------------

$mq-support: false;
$mq-fixed-value: 1024;

// Renders at fixed value
@include bp (min-width, 300px) { 
    div { color:#000; }
}

// Doesn't render without MQ support
@include bp (min-width, 1200px) { 
    div { color:#FFF; }
}

// Doesn't render without MQ support
@include bp (max-width, 300px) { 
    div { color:#444; }
}

// Renders at fixed value
@include bp (max-width, 1200px) { 
    div { color:#888; }
}

// ----------------------------------------------
*/
.product-view .product-collateral.accordion-open .collateral-tabs .tab.current:after {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.product-view .product-collateral .collateral-tabs .tab:after {
  right: 15px;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  top: 15px;
}

/* ============================================ *
 * Homepage
 * ============================================ */
/* -------------------------------------------- *
 * Primary Banner
 */
body.cms-home .main-container {
  padding-top: 0;
}

body.cms-home .slideshow-container {
  margin-top: 0;
}

.slideshow .banner-msg {
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  top: 30%;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.slideshow .banner-msg h2 {
  color: #FFFFFF;
  font-size: 24px;
  text-shadow: 1px, 1px, 3px, false, #555555;
}

.slideshow .banner-msg h2 strong {
  font-weight: bold;
  display: block;
  font-size: 36px;
}

@media only screen and (max-width: 770px) {
  .slideshow .banner-msg h2 {
    font-size: 20px;
  }
  .slideshow .banner-msg h2 strong {
    font-size: 24px;
  }
}

@media only screen and (max-width: 479px) {
  .slideshow .banner-msg {
    top: 20%;
  }
  .slideshow .banner-msg h2 {
    font-size: 14px;
  }
  .slideshow .banner-msg h2 strong {
    font-size: 16px;
  }
}

/* -------------------------------------------- *
 * Promotion Banner Section
 */
.promos {
  margin: 0 0 15px 0;
  padding: 0;
  width: 100%;
}

.promos:after {
  content: '';
  display: table;
  clear: both;
}

/* Specifying the body only in order to override the .std ul li styling */
body .promos > li {
  margin: 0 0 10px 0;
  list-style: none;
  text-align: center;
  position: relative;
  border: 1px solid #CCCCCC;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.promos > li:last-child {
  margin-bottom: 0;
}

.promos img {
  max-width: 100%;
  width: 100%;
}

.promos a:hover {
  opacity: 0.8;
  display: block;
}

.promos span {
  color: #FFFFFF;
  text-transform: uppercase;
  position: absolute;
  display: block;
  width: 100%;
  top: 10%;
  font-weight: 500;
  font-size: 20px;
  font-family: "Raleway", "Helvetica Neue", Verdana, Arial, sans-serif;
  text-shadow: 1px, 1px, 3px, false, #555555;
}

.promos strong {
  font-weight: 600;
  font-size: 26px;
  display: block;
}

@media only screen and (min-width: 771px) {
  .promos span {
    font-size: 16px;
  }
  .promos strong {
    font-size: 17px;
  }
  /* Config: Three columns + flexible gutter */
  body .promos > li {
    float: left;
    width: 31.74603%;
    margin-right: 2.38095%;
  }
  .promos > li:nth-child(3n) {
    margin-right: 0;
  }
}

@media only screen and (min-width: 880px) {
  .promos span {
    font-size: 18px;
  }
  .promos strong {
    font-size: 24px;
  }
}

/* -------------------------------------------- *
 * New Products Section
 */
.cms-index-index h2.subtitle {
  padding: 6px 0;
  text-align: center;
  color: #fe0000;
  font-weight: 600;
  border-bottom: 1px solid #CCCCCC;
  border-top: 1px solid #CCCCCC;
}

.cms-index-noroute h2.subtitle {
  display: none;
}

.owl-dots {
  text-align: center;
}

.owl-dots button.owl-dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  background: #ccc;
  margin: 0 3px;
}

.owl-dots button.owl-dot.active {
  background-color: gray;
}

.cms-home .success-msg, .cms-home .error-msg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  background: transparent;
}

.cms-home .success-msg li, .cms-home .error-msg li {
  border-left: none;
  border-radius: 0 0 10px 10px;
  border: none;
  color: white !important;
  text-align: center;
  font-weight: 300;
  box-shadow: 0px 0px 25px 0px rgba(80, 80, 80, 0.3);
  height: 60px;
}

@media only screen and (min-width: 600px) {
  .cms-home .success-msg li, .cms-home .error-msg li {
    height: 40px;
  }
}

.cms-home .success-msg li br, .cms-home .error-msg li br {
  display: none;
}

.cms-home .success-msg li {
  background: #1C9D0A;
  background-color: #1C9D0A !important;
}

.cms-home .error-msg li {
  background: #2C2C2C;
  background-color: #2C2C2C !important;
}

.title h2 {
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 18px;
}

.banners-info img {
  border: 1px solid #EEEEEE;
}

.modal-backdrop {
  z-index: 2000;
}

.pop-up-omv {
  z-index: 2050 !important;
}

.pop-up-omv .modal-dialog {
  top: 45%;
  transform: translateY(-50%) !important;
}

.pop-up-omv .modal-content {
  box-shadow: none;
  border-radius: 0px;
  border: none;
}

.pop-up-omv .modal-content .modal-body {
  padding: 0 15px;
}

.pop-up-omv .modal-content .modal-body img {
  width: 100%;
}

.pop-up-omv .modal-content .modal-body .col-lg-12 {
  padding: 0;
}

.pop-up-omv .modal-content .modal-body button.close {
  position: absolute;
  right: -15px;
  top: -15px;
  color: white;
  opacity: unset;
  background: #fe0000;
  font-size: 25px;
  height: 40px;
  width: 40px;
  border-radius: 50px;
  font-weight: 300;
  padding-bottom: 1px;
}

@media only screen and (max-width: 768px) {
  .pop-up-omv .modal-content .modal-body button.close {
    right: 5px;
    top: 5px;
  }
  .pop-up-omv .modal-dialog {
    top: 45%;
  }
  .pop-up-omv .modal-content .modal-body button.close {
    height: 30px;
    width: 30px;
    padding-bottom: 3px;
  }
}

.nav-tabs {
  background: white;
  padding: 0;
  border-bottom: none !important;
}

.nav-tabs .navbar-toggler {
  width: 70%;
  background: #fe0000;
  color: white;
  height: 40px;
  border-radius: 0;
  transition: all .3s;
}

.nav-tabs .navbar-toggler .navbar-text {
  color: white;
  font-size: 1.4rem;
}

.nav-tabs .navbar-toggler .navbar-toggler-icon {
  background-image: none;
}

.nav-tabs .navbar-toggler:focus {
  outline: none;
}

@media only screen and (min-width: 769px) {
  .nav-tabs .navbar-nav {
    width: 100%;
  }
  .nav-tabs .navbar-nav .nav-link {
    height: 40px;
    line-height: 40px;
    padding: 0 15px;
    margin: 0 15px;
  }
  .nav-tabs .navbar-nav .nav-link.active {
    color: white;
  }
}

@media only screen and (max-width: 768px) {
  .nav-tabs .navbar-collapse {
    width: 70%;
    position: absolute;
    z-index: 2;
    background: white;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0 0 6px 6px;
    margin-top: 10px;
    text-align: center;
    padding: 10px;
    border: 1px solid white;
    transition: all .3s;
    opacity: 0;
  }
  .nav-tabs .navbar-collapse.show {
    opacity: 1;
    border: 1px solid lightgray;
  }
  .nav-tabs .navbar-collapse .nav-link {
    margin-bottom: 5px;
  }
  .nav-tabs .navbar-collapse .nav-link.active {
    background: white;
  }
  .nav-tabs .navbar-collapse .nav-link:last-child {
    margin-bottom: 0px;
  }
}

/* slider mobile desktop display

#rev_slider_2_wrapper{
    @include bp(min-width, $bp-small+1){
        display: none !important;
    }
}

.forcefullwidth_wrapper_tp_banner,
#rev_slider_1_wrapper{
    display: none !important;
    @include bp(min-width, $bp-small+1){
        display: block !important;
    }
}

*/
/* ============================================ *
 * Category Landing Pages
 * ============================================ */
.category-image {
  position: relative;
}

.categorylandingMainblock {
  border: 1px solid #E2E2E2;
  padding: 7px;
}

.catblocks {
  float: left;
  padding: 0 0 20px 0;
  width: 100%;
}

.catblocks li {
  position: relative;
  float: left !important;
  width: 23% !important;
  margin-right: 2.66667% !important;
  margin-bottom: 10px;
  border: 1px solid #CCCCCC;
  padding: 10px;
}

.catblocks li:hover {
  border-color: #2C2C2C;
}

@media only screen and (max-width: 770px) {
  .catblocks li {
    width: 49% !important;
    margin-right: 2% !important;
  }
  .catblocks li:nth-child(even) {
    margin-right: 0 !important;
  }
}

@media only screen and (max-width: 479px) {
  .catblocks li {
    width: 100% !important;
    margin-right: 0 !important;
    float: none;
  }
}

.catblocks li:last-child {
  margin-right: 0 !important;
}

.catblocks li img {
  width: 100%;
  max-width: 100%;
}

.catblocks li a span {
  color: #FFFFFF;
  position: absolute;
  background-color: #2C2C2C;
  font-family: raleway;
  font-size: 17px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  bottom: 0px;
  left: 0px;
  width: 100%;
  padding: 5px 10px;
  margin-right: 10px;
}

.catblocklinks a {
  color: #FFFFFF;
  text-decoration: none;
}

.catlandingbottomPromoblock {
  float: left;
  width: 100%;
  border: 1px solid #E2E2E2;
  margin-bottom: 100px;
}

.catalog-category-view div.categoryland-caption {
  display: block;
  margin: 0;
  position: absolute;
  color: #000000;
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
}

.catalog-category-view div.categoryland-caption span.blue-big-text {
  color: #3399CC;
  font-size: 50px;
  font-weight: 800;
  padding: 0 0 0 8px;
}

.catalog-category-view div.categoryland-caption span.blue-big-text {
  padding: 0 !important;
}

.catalog-category-view div.categoryland-caption span.three-dots {
  background: url(../images/bg_threeDots.png) no-repeat center center;
  display: block;
  height: 20px;
}

.catalog-category-view div.categoryland-caption p.desc {
  font: italic 18px/28px Georgia, "Times New Roman", Times, serif;
  color: #656565;
}

.cms-home #homepage-main-slides div.slides_control div div.slideshow-caption a.link:hover,
.cms-home #homepage-main-slides div.slides_control div div.slideshow-caption span.link:hover {
  text-decoration: underline;
  color: #1189C5;
}

.cms-home #homepage-main-slides div.slides_control div div.slideshow-caption span.blue-big-text,
.catalog-category-view div.categoryland-caption span.blue-big-text {
  color: #3399CC;
  font-size: 50px;
  font-weight: 800;
  padding: 0 0 0 8px;
}

.catalog-category-view div.categoryland-caption span.blue-big-text {
  padding: 0 !important;
}

.cms-home #homepage-main-slides div.slides_control div div.slideshow-caption span.three-dots,
.catalog-category-view div.categoryland-caption span.three-dots {
  background: url(../images/bg_threeDots.png) no-repeat center center;
  display: block;
  height: 20px;
}

.cms-home #homepage-main-slides div.slides_control div div.slideshow-caption p.desc,
.catalog-category-view div.categoryland-caption p.desc {
  font: italic 18px/28px Georgia, "Times New Roman", Times, serif;
  color: #656565;
}
